// App.js
import React from 'react';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import headerImage from './foto.png';
import Icon1 from './icon1.png';
import Icon2 from './icon2.png';
import Icon3 from './icon3.png';
import Icon4 from './icon4.png';
import Icon5 from './icon5.png';
import Icon6 from './icon6.png';
import Icon7 from './icon7.png';
import Icon8 from './icon8.png';

library.add(faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook );

const ScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const ProtocoloPersonalizadoPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Protocolo Personalizado</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const AcompanhamentoMensalPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Acompanhamento Mensal durante 3 meses</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const ComunidadePage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Comunidade no Whatsapp</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const NutricionistaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>2 sessões de Consultoria com Nutricionista</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const PersonalTrainerPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>2 sessões de Consultoria com Personal Trainer</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const SobrePage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Sobre o Dr. Danilo Matsunaga</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const PerguntasFrequentesPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>FAQ - Perguntas Frequentes</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const AtendimentoPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Atendimento Especializado</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #ddd', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);






const App = () => (
  <Router>
    <div>
      <header style={{ textAlign: 'center' }}>
      <Link to="/"><img src={headerImage} alt="DANILO MATSUNAGA" title="DANILO MATSUNAGA" style={{ width: '150px', marginTop: '20px' }} /></Link>
      </header>
      
      
      
      <Routes>
      
          <Route path="/" element={

<div>
  
  <h2 style={{ textAlign: 'center', margin: '30px 30px 0px 30px', color: '#7a66fd' }}>A menopausa não será mais um problema para você!</h2>
  <p style={{ textAlign: 'center', margin: '15px 30px 20px 30px', color: '#838383' }}>Com o MenoPlus 360, você terá acompanhamento frequente e o cuidado próximo de um médico especializado, para superar os sintomas desta fase.</p>
          
          <div className="container">
        
           
              <div className="item">
                <Link to="/protocoloPersonalizado" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon4} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Protocolo Personalizado</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/acompanhamentoMensal" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon5} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Acompanhamento Mensal durante 3 meses</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/comunidade" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon2} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Comunidade no Whatsapp</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/nutricionista" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon7} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>2 sessões de Consultoria com Nutricionista</small>
                </Link>
              </div>           
              <div className="item">
                <Link to="/personalTrainer" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon6} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>2 sessões de Consultoria com Personal Trainer</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/sobre" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon3} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Sobre o Dr. Danilo Matsunaga</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/perguntasFrequentes" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon1} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>FAQ - Perguntas Frequentes</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/atendimento" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon8} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Atendimento Especializado</small>
                </Link>
              </div>
              {/* Add more links for other icons as needed */}
            
          
          </div></div>
          } />
          <Route path="/protocoloPersonalizado" element={<ProtocoloPersonalizadoPage />} />
          <Route path="/acompanhamentoMensal" element={<AcompanhamentoMensalPage />} />
          <Route path="/comunidade" element={<ComunidadePage />} />
          <Route path="/nutricionista" element={<NutricionistaPage />} />
          <Route path="/personalTrainer" element={<PersonalTrainerPage />} />
          <Route path="/sobre" element={<SobrePage />} />
          <Route path="/perguntasFrequentes" element={<PerguntasFrequentesPage />} />
          <Route path="/atendimento" element={<AtendimentoPage />} />
          {/* Add routes for other items as needed */}
      </Routes>
      <footer style={{ textAlign: 'center', padding: '20px', color: '#838383' }}>
        Copyright 2024
      </footer>
    </div>
  </Router>
);

export default App;















// function App() {
//   return (
//     <div style={{ margin: '27px' }}>
//       <header>
//       <div style={{ textAlign: 'center' }}><img alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" src={Foto} style={{ width: '200px' }} /></div>
//         <p className="gradient">A menopausa não será mais um problema para você!</p>
//         <p>Com o MenoPlus 360, você terá acompanhamento frequente e o cuidado próximo de um médico especializado, para superar os sintomas desta fase.</p>
//         <div style={{ textAlign: 'center' }}><a href="https://sun.eduzz.com/2178492" target="_blank" rel="noreferrer">
//           <img src={Bot} alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" style={{ width: '250px', borderRadius: '10px' }} />
//         </a>
//         </div>
//         </header> 
//         <p className="gradient">Volte a viver como antes:</p>
//         <ul>
//           <li>Sem sofrer com o calor insuportável;</li>
//           <li>Satisfeita com seu relacionamento íntimo;</li>
//           <li>Livre da dificuldade para emagrecer;</li>
//           <li>Disposta para as atividades do dia a dia;</li>
//           <li>Bem-humorada e de bem com a vida.</li>
//         </ul>
//         <p className="gradient">O Protocolo MenoPlus 360 é para um único tipo de mulher:</p>
//         <p>A mulher que não aguenta mais viver refém dos sintomas da menopausa e que deseja recuperar o controle da própria vida.</p>
//         <div style={{ textAlign: 'center' }}>
//           <a href="https://sun.eduzz.com/2178492" target="_blank" rel="noreferrer">
//           <img alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" src={Bot} style={{ width: '250px', borderRadius: '10px' }} />
//           </a>
//         </div>
//         <p className="gradient">O Protocolo MenoPlus 360 te dá direito a:</p>
//         <ul>
//           <li><b>Protocolo Personalizado</b> - Pensado exclusivamente para você, para resolver o seu problema de uma vez por todas;</li>
//           <li><b>Acompanhamento Mensal durante 3 meses</b> - Em grupo, com praticidade e agilidade;</li>
//           <li><b>Comunidade no Whatsapp</b> - Uma verdadeira rede de apoio entre mulheres com o mesmo objetivo em comum;</li>
//           <li><b>2 sessões de Consultoria com Nutricionista</b> - Porque o cuidado com a alimentação é primordial nessa importante fase da mulher;</li>
//           <li><b>2 sessões de Consultoria com Personal Trainer</b> - Para que você trabalhe os exercícios corretos e recomendados para o seu corpo.</li>
//         </ul>
//         <p>Eu quero ajudar você a se libertar dos sintomas da menopausa e por isso quero que tenha acesso a:</p>
//         <p>Protocolo Personalizado (No valor de R$2.500,00)</p>
//         <p>Acompanhamento Mensal em Grupo (No valor de R$600,00)</p>
//         <p>Comunidade no Whatsapp (No valor de R$2.500,00)</p>
//         <p>2 Sessões de consultoria com Nutricionista (No valor de R$500,00)</p>
//         <p>2 sessões de consultoria com Personal Trainer (No valor de R$500,00)</p>
//         <p>Não por R$5.300,00 (que é o que vale), mas sim</p>
//         <p>Por Apenas</p>
//         <p>12x de R$199,70</p>
//         <p>Apenas nas primeiras 72h</p>
//         <p>Um investimento muito baixo, se comparado à oportunidade de recuperar sua vida como era antes da menopausa.</p>
        
//         <div style={{ textAlign: 'center' }}><a href="https://sun.eduzz.com/2178492" target="_blank" rel="noreferrer">
//           <img src={Dr} alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" style={{ width: '250px', borderRadius: '10px' }} />
//         </a>
//         </div>
       
//         <p>Muito prazer,</p>
//         <p>Sou o Dr. Danilo Matsunaga, médico, com mais de 5 mil atendimentos realizados e graduado com experiência na área de nutrologia, implantes e terapias injetáveis.</p>
//         <p>Meu principal objetivo é cuidar da saúde e do bem-estar de mulheres que desejam uma vida mais saudável e plena após os 40 anos.</p>
//         <p>Minha jornada profissional começou a partir de uma experiência pessoal, na qual superei um quadro de sobrepeso e depressão, tendo nessa vivência, a inspiração necessária para buscar conhecimento e poder oferecer tratamentos personalizados e eficazes.</p>
//         <p>Acredito na medicina integrativa e costumo olhar para as minhas pacientes de maneira ampla, pois entendo que elas não buscam um tratamento por estarem doentes, mas sim, porque desejam viver mais e melhor.</p>
        
//         <p>Atenção: Nós não compartilharemos seu contato com terceiros. O tratamento de dados nesta empresa acontece em conformidade com a Lei Geral de Proteção de Dados (LGPD). Você está seguro(a) e poderá se descadastrar a qualquer momento.</p>
//         <p>Política de Privacidade - Termos de Uso © Dr. Danilo Matsunaga - Todos os Direitos Reservados</p>
//       </div>
//   );
// }

// export default App;
